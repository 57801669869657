import CommonIcon from '@/components/common-icon';
import { useGoogleLogin, useResponsive } from '@/core/hooks';
import { LANG } from '@/core/i18n/src/page-lang';
import { AppleLogin, AppleLoginType } from '@/core/shared';
import { GoogleLogin } from '@/core/shared/src/account/google-login';
import { MediaInfo } from '@/core/utils';
import { clsx } from '@/core/utils/src/clsx';
import { message } from '@/core/utils/src/message';
import { useEffect } from 'react';
import { store } from '../store';

export const ThirdPartBtns = () => {
  const { isMobile } = useResponsive(false);
  const { ru } = store;
  const { GoogleLoginCallback, postOauthLogin } = useGoogleLogin(ru);
  const GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_LOGIN_CLIENT_ID;

  useEffect(() => {
    GoogleLogin(GoogleLoginCallback);
  }, []);
  const _AppleLogin = async () => {
    try {
      const idToken: string = await AppleLogin(AppleLoginType.LOGIN);
      if (!idToken) {
        return message.error(LANG('登录失败'));
      }
      await postOauthLogin({ type: 'apple', idToken, scene: 'APPLE_LOGIN' });
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <div className={clsx('third-part-btns', isMobile && 'mobile')}>
        <div className='or-wrapper'>
          <div>{LANG('or continue with')}</div>
        </div>
        <div className='btns'>
          <div>
            <div id='my-google-login-btn' style={{ opacity: 0 }} className='google-login'>
              <div
                id='g_id_onload'
                data-client_id={GOOGLE_CLIENT_ID}
                data-context='signin'
                data-ux_mode='popup'
                data-callback='google_login_callback'
                data-auto_prompt='false'
              />
              <div
                className='g_id_signin'
                data-type='standard'
                data-shape='rectangular'
                data-theme='outline'
                data-text='signin_with'
                data-size='large'
                data-logo_alignment='left'
                data-width='726'
                data-height='60'
              />
            </div>
            <CommonIcon name='external-google-0' size={20} />
            <div>Google</div>
          </div>
          <div onClick={_AppleLogin} className='apple-login'>
            <CommonIcon name='external-apple' size={20} />
            <div>Apple</div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .third-part-btns {
          padding-bottom: 16px;
          .or-wrapper {
            margin: 24px 0 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            color: var(--spec-font-color-1);
          }
          .btns {
            display: flex;
            justify-content: center;
            align-items: center;
            #my-google-login-btn {
              position: absolute;
              font-size: 12px;
              width: 100%;
              height: 100%;
              overflow: hidden;
              font-weight: 500;
              :global(*) {
                font-size: 12px;
                position: absolute;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              :global(#g_id_onload) {
                width: 0;
                height: 0;
              }
            }
            > div {
              position: relative;
              cursor: pointer;
              background: var(--spec-background-color-3);
              flex: 1;
              height: 40px;
              padding: 10px 12px;
              display: flex;
              font-size: 14px;
              justify-content: center;
              font-weight: 500;
              align-items: center;
              border-radius: 6px;
              color: var(--spec-font-color-1);

              &:first-child {
                margin-right: 12px;
                &:hover {
                  background-color: var(--spec-hover-background-color-3);
                }
              }
              > *:last-child {
                margin-left: 6px;
              }
            }
            @media ${MediaInfo.mobile} {
              display: flex;
              align-items: center;
            }
            .apple-login {
              z-index: 9;
              &:hover {
                background-color: var(--spec-hover-background-color-3);
              }
            }
          }
        }
      `}</style>
    </>
  );
};
