import CommonIcon from '@/components/common-icon';
import { LANG } from '@/core/i18n/src/page-lang';
import { EVENT_NAME, EVENT_TRACK } from '@/core/sensorsdata';
import { QrCodeLogin } from '@/core/shared';
import QRCode from 'qrcode.react';
import { useCallback, useEffect, useState } from 'react';
import css from 'styled-jsx/css';

export const LoginQrCode = (props: { onLoginSuccess: () => void }) => {
  const { onLoginSuccess } = props;
  const [code, setCode] = useState(QrCodeLogin.qrcode);
  const _getCode = useCallback(async () => {
    return QrCodeLogin.getQrcode({ animate: true }).then((data) => {
      QrCodeLogin.killKeepAliveTimer();
      setCode(data.data);
    });
  }, []);
  useEffect(() => {
    const func = async () => {
      !code && (await _getCode());
      if (code) {
        EVENT_TRACK(EVENT_NAME.SubRegisterSign, {
          type: '扫码',
          is_register: false,
          invite_method: '无',
          precise_invite_channel: '',
          invite_code: '无',
        });
        QrCodeLogin.keepAliveVerifyQrcode().then(({ data }) => {
          // 二维码过期 -1:二维码过期 0:未验证 1:成功
          const status = Number(data?.status);
          if (status === -1) {
            _getCode();
          } else if (status === 1) {
            onLoginSuccess();
          }
        });
      }
    };
    func();
    return () => QrCodeLogin.killKeepAliveTimer();
  }, [code]);
  const renderQrCode = () => {
    if (code) {
      return (
        <QRCode
          size={210}
          value={code}
          level={'Q'}
          imageSettings={{
            src: '/favicon.ico',
            x: undefined,
            y: undefined,
            height: 40,
            width: 40,
            excavate: true,
          }}
        />
      );
    }
    return null;
  };
  return (
    <div className='qrcode-container'>
      <div className='main-content'>
        <div className='code'>{renderQrCode()}</div>
        <div className='info'>
          <CommonIcon name='common-scan' width='16' height='20' className='scan-icon' />
          <div
            className='text'
            dangerouslySetInnerHTML={{
              __html: LANG('打开{appName}移动端扫码登录', { appName: '<span>BYDFi</span>' }),
            }}
          ></div>
        </div>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};
const styles = css`
  .qrcode-container {
    margin-top: 80px;
    .main-content {
      margin-top: 6px;
      .code {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        background-color: #fff;
        border: 1px solid var(--skin-color-active);
        width: 254px;
        height: 254px;
        border-radius: 16px;
      }
      .info {
        margin-top: 80px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        :global(.scan-icon) {
          width: 20px;
          height: 20px;
          margin-right: 9px;
        }
        .text {
          font-size: 14px;
          font-weight: 500;
          color: var(--spec-font-color-1);
          :global(span) {
            color: var(--skin-main-font-color);
          }
        }
      }
    }
  }
`;
