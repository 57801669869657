import { Button } from '@/components/button';
import { Loading } from '@/components/loading';
import { postCommonAppsFlyerApi } from '@/core/api';
import { useLocalStorage, useRouter } from '@/core/hooks';
import { LANG } from '@/core/i18n/src/page-lang';
import { EVENT_NAME, EVENT_TRACK, SENSORS_LOGIN } from '@/core/sensorsdata';
import { Account } from '@/core/shared/src/account';
import { LOCAL_KEY, SESSION_KEY } from '@/core/store';
import { decryptAuthData, encryptAuthData, getCookie, getOtherLink } from '@/core/utils';
import { encryptPassport, getIdentity } from '@/core/utils/src/crypto';
import { getFParam, getPlatform, getUrlQueryParams } from '@/core/utils/src/get';
import { message } from '@/core/utils/src/message';
import { useEffect } from 'react';
import { store } from '../store';

export const RegisterButton = (props: {
  shouldDisableBtn: boolean;
  type: LOCAL_KEY;
  btnText?: string;
  onRegisterFailed: () => void;
}) => {
  const backUrl = sessionStorage[SESSION_KEY.LOGIN_REDIRECT] || '';
  const { shouldDisableBtn, type, btnText = LANG('注册'), onRegisterFailed } = props;
  const router = useRouter();
  const [, setState] = useLocalStorage(LOCAL_KEY.FIRST_REGISTER_MODAL_VISIBLE, false);
  const { email, password, countryCode, phone, countryId, smsCode, emailCode, ru } = store;
  const account = type === LOCAL_KEY.INPUT_REGISTER_PHONE ? countryCode + phone : email;
  const phoneNumber = countryCode + (phone || '').replace(/^0*/, '');
  const terminal = getPlatform();
  const f = getFParam();
  const sourceParam = getUrlQueryParams('source') || getCookie('SOURCE');
  const clacParam = getUrlQueryParams('clac') || getCookie('CLAC');
  const loginParam = {
    username: account,
    password: encryptAuthData(password),
    vHash: getIdentity(32),
    terminal,
  };
  const onRegisterDone = async (uid: string) => {
    SENSORS_LOGIN(uid);
    store.isVerifySuccess = true;
    setState(true);
    await Account.refreshUserInfo();
    // 特殊判断
    if (getOtherLink() !== null) {
      window.location.href = getOtherLink() as string;
    }
  };
  useEffect(() => {
    return () => {
      store.ru = '';
    };
  }, []);

  const _onRegisterSuccess = async () => {
    const res = await Account.login(loginParam);
    if (res.code === 200) {
      try {
        const uid = res.data.uid;
        const afData = getCookie('AF_DATA') || '';
        const decodeAfData = decodeURIComponent(afData);
        if (afData) {
          const decryptAfData = decryptAuthData(decodeAfData);
          const { eventName, eventValue, afid, afappid, afauth, brand } = JSON.parse(decryptAfData) as any;
          await postCommonAppsFlyerApi({
            eventName,
            eventValue,
            afappid,
            afauth,
            afid,
            brand: brand,
            customer_user_id: uid,
          });
        }
        onRegisterDone(uid);
        if (!backUrl) {
          router.push('/');
        } else {
          window.location.href = backUrl; // 自带locale
        }
      } catch (error) {
        console.error('_onRegisterSuccess error', error);
      }
    }
  };
  const _registerByPhone = async () => {
    if (password?.length > 30) {
      message.error(LANG('密码不能超过30位'));
      Loading.end();
      return;
    }
    const params: any = {
      password: encryptAuthData(password),
      countryCode: String(countryCode),
      phone: phoneNumber,
      countryId,
      terminal,
      code: smsCode,
    };
    if (ru) {
      params.ru = ru;
    }
    if (f) {
      params.f = f;
    }
    if (sourceParam) {
      params.source = sourceParam;
    }
    if (clacParam) {
      params.clac = clacParam;
    }
    EVENT_TRACK(EVENT_NAME.SubRegisterSign, {
      type: '手机号',
      is_register: true,
      invite_method: '无',
      precise_invite_channel: '',
      invite_code: ru,
    });
    const registerResult = await Account.registerUser({
      sign: encryptPassport(params),
      ...params,
    });
    if (registerResult?.code === 200) {
      await _onRegisterSuccess();
    } else {
      onRegisterFailed?.();
      store.isVerifySuccess = false;
      message.error(registerResult.message);
    }
    Loading.end();
  };
  const _registerByEmail = async () => {
    if (password?.length > 30) {
      message.error(LANG('密码不能超过30位'));
      Loading.end();
      return;
    }
    const resetParam = { password: encryptAuthData(password), email, terminal, code: emailCode } as any;
    if (ru) {
      resetParam.ru = ru;
    }
    if (f) {
      resetParam.f = f;
    }
    if (sourceParam) {
      resetParam.source = sourceParam;
    }
    if (clacParam) {
      resetParam.clac = clacParam;
    }
    const data = {
      sign: encryptPassport(resetParam),
      ...resetParam,
    };
    EVENT_TRACK(EVENT_NAME.SubRegisterSign, {
      type: '邮箱',
      is_register: true,
      invite_method: '无',
      precise_invite_channel: '',
      invite_code: ru,
    });
    const res = await Account.registerUser(data);
    if (res?.code === 200) {
      await _onRegisterSuccess();
    } else {
      store.isVerifySuccess = false;
      onRegisterFailed?.();
      message.error(res.message);
    }
    Loading.end();
  };
  const handleRegisterUser = async () => {
    try {
      if (shouldDisableBtn) return;
      Loading.start();
      if (type === LOCAL_KEY.INPUT_REGISTER_PHONE) {
        await _registerByPhone();
      } else {
        await _registerByEmail();
      }
    } catch (err: any) {
      Loading.end();
      message.error(err.message);
    }
  };
  return (
    <Button
      type='primary'
      className={shouldDisableBtn ? 'disabled' : ''}
      height={44}
      style={{ width: '100%' }}
      onClick={handleRegisterUser}
    >
      {btnText}
    </Button>
  );
};
