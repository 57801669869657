import { clsx } from '@/core/utils/src/clsx';
import { MediaInfo } from '@/core/utils/src/media-info';
import React, { useEffect, useState } from 'react';
import css from 'styled-jsx/css';
import { store } from '../store';

type Tab = {
  label: string;
  children: React.ReactNode;
  tips?: string;
};

export const Tabs = ({ items, onTabChange }: { items: Tab[]; onTabChange?: (index: number) => void }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    store.curTab = activeIndex;
  }, [activeIndex]);
  const handleTabChange = (index: number) => {
    setActiveIndex(index);
    onTabChange?.(index);
  };
  return (
    <div className='tabs-wrapper'>
      <ul className='tabs-title'>
        {items.map((tab, index) => (
          <li
            key={index}
            onClick={() => handleTabChange(index)}
            className={clsx('tabs-item', activeIndex === index ? 'active-tab' : '')}
          >
            {tab.label}
          </li>
        ))}
      </ul>
      <div className='tabs-content'>
        {items[activeIndex]?.tips ? <p className='tips'>{items[activeIndex].tips ?? ''}</p> : null}
        {items[activeIndex].children}
      </div>
      <style jsx>{styles}</style>
    </div>
  );
};
const styles = css`
  .tabs-wrapper {
    .tabs-title {
      margin-left: 0px;
      display: flex;
      align-items: center;
      padding: 0;
      margin-bottom: 0;
      margin-top: 0px;
      @media ${MediaInfo.mobile} {
        border-bottom: none;
      }
      :global(.tabs-item) {
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--spec-font-color-2);
        font-weight: 500;
        cursor: pointer;
        padding-left: 8px;
        padding-right: 8px;
        font-size: 14px;
        &:hover {
          padding-left: 8px;
          padding-right: 8px;
          height: 28px;
          border-radius: 4px;
          background-color: var(--spec-background-color-3);
        }
        &:not(:first-child) {
          margin-left: 8px;
        }
      }
      :global(.active-tab) {
        background-color: var(--spec-background-color-3);
        border-radius: 4px;
        height: 28px;
        color: var(--skin-main-font-color);
      }
    }
    .tabs-content {
      padding: 20px 0px 0px;
      .tips {
        font-size: 14px;
        font-weight: 400;
        background-color: var(--skin-primary-bg-color-opacity-3);
        color: var(--spec-font-color-1);
        padding: 10px;
        margin: 0 0 35px;
        border-radius: 6px;
      }
      :global(.qrcode-login) {
        padding: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
`;
